<template>
  <div :class="{ 'has-label': !!label }">
    <label v-if="!!label" :for="id">{{ label }}</label>
    <div class="datepicker-wrapper">
      <div v-if="!event.menuVisible" class="dates" :class="{ timezone: mode === 'datetime', disabled }">
        <span v-if="shouldShowDate" class="representation">My time: {{ dateRepresentation }}</span>
        <date-picker
          :model-value="localValue"
          :timezone="timezone"
          :mode="mode"
          :minute-increment="30"
          :masks="masks"
          :attributes="{ order: 1000 }"
          :min-date="minDate"
          :max-date="maxDate"
          :valid-hours="hoursLimit"
          :title="mode === 'datetime' ? 'Date and time' : 'Date'"
          @update:model-value="onInput"
        >
          <template #default="{ inputValue, togglePopover }">
            <input
              :id="id"
              class="bg-white border px-2 py-1 rounded"
              :placeholder="placeholder"
              :value="inputValue"
              :disabled="disabled"
              @focus="togglePopover"
              @blur="mode === 'date' && complete"
              @keypress.enter="complete"
              @paste="paste"
            />
            <div v-if="showWarning" class="warning">
              <Icon color="warning" name="alert" size="s" />
              Selected value does not match limits
            </div>
          </template>
        </date-picker>
        <multiselect
          v-if="mode === 'datetime'"
          :disabled="disabled"
          :can-clear="false"
          :value="timezone"
          :options="timezones"
          title="Timezone"
          @change="onTimezoneChanged"
        />
      </div>

      <div v-if="calendarAvaliable" class="event">
        <TextField
          v-if="mode === 'datetime'"
          v-model="event.duration"
          type="number"
          :limits="{ min: 0 }"
          class="left-marging"
          title="Duration (hours)"
        />
        <Button
          v-if="(event.calendars.length && !event.menuVisible) || event.busy"
          variant="icon"
          class="event-button"
          :class="{ created: event.created && !needsReschedule }"
          :disabled="!localValue || event.busy"
          :color="!event.busy && needsReschedule && 'warning'"
          :title="needsReschedule ? 'Dates don’t match. Click to move the calendar apointment.' : 'Add to my calendar'"
          @click="addToCalendar"
          ><Icon :name="event.busy ? 'loading' : event.created && !needsReschedule ? 'calendar-check' : 'calendar-plus'" :spin="event.busy" />
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import httpClient from '@/utils/httpClient';

import { parse, set, isValid } from 'date-fns';
import Cleave from 'cleave.js';
import './Datefield.style.css';
import { DatePicker } from 'v-calendar';
import Multiselect from '@/components/common/Multiselect';
import moment from 'moment-timezone';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import TextField from '@/components/common/TextField';

export default {
  components: {
    DatePicker,
    Multiselect,
    Button,
    Icon,
    TextField
  },
  props: {
    id: {
      type: String,
      default: () =>
        Math.random()
          .toString(36)
          .substr(2, 5)
    },
    value: {
      type: [String, Date],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'MM/DD/YYYY'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'date'
    },
    eventSubject: {
      type: String,
      default: ''
    },
    eventBody: {
      type: String,
      default: ''
    },
    limits: {
      type: Object,
      default: null
    },
    calendarEventProperties: {
      type: Object,
      default: null
    },
    calendarEvent: {
      type: Object,
      default: null
    },
    calendarAvaliable: {
      type: Boolean,
      default: true
    }
  },
  emits: ['input', 'change', 'update:modelValue', 'update:value', 'calendarEventCreated'],
  data() {
    let localValue;
    let minDate;
    let maxDate;
    let hoursLimit;
    let showWarning;
    const myZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let timezone;
    const timezones = [
      { label: `My Time - ${myZone} (${moment.tz(myZone).zoneAbbr()})`, value: myZone },
      { label: 'USPTO - Eastern Time (ET)', value: 'America/New_York' },
      { label: 'EPO - Central European Time (CET)', value: 'Europe/Berlin' },
      { label: 'JPO - Japan Standard Time (JST)', value: 'Asia/Tokyo' },
      { label: 'KIPO - Korea Standard Time (KST)', value: 'Asia/Seoul' },
      { label: 'CNIPA - China Standard Time (CST)', value: 'Asia/Hong_Kong' }
    ];
    if (this.value) {
      localValue = new Date(this.value);
    }
    if (this.mode === 'datetime') {
      const zone = timezones.find(
        z =>
          moment(this.value)
            .tz(z.value)
            .format() === this.value
      );
      timezone = zone ? zone.value : myZone;
    }

    if (this.limits) {
      if (this.limits.days && this.limits.days.min) {
        minDate = new Date(moment().add(Number(this.limits.days.min), 'days'));
        if (localValue < minDate) {
          showWarning = true;
        }
      }
      if (this.limits.days && this.limits.days.max) {
        maxDate = new Date(moment().add(Number(this.limits.days.max), 'days'));
        if (localValue > maxDate) {
          showWarning = true;
        }
      }
      if (this.limits.hours) {
        hoursLimit = {
          min: null,
          max: null
        };
        if (this.limits.hours.min) {
          hoursLimit.min = this.limits.hours.min;
        }
        if (this.limits.hours.max) {
          hoursLimit.max = this.limits.hours.max - 1;
        }
      }
    }
    return {
      instance: null,
      localValue,
      timezone,
      myZone,
      timezones,
      masks: {
        input: 'MM/DD/YYYY'
      },
      event: {
        subject: this.eventSubject,
        body: this.eventBody,
        busy: false,
        menuVisible: false,
        created: !!this.calendarEvent,
        calendars: [],
        duration: (this.calendarEventProperties?.event?.duration && Number.parseFloat(this.calendarEventProperties?.event?.duration)) || 1
      },
      minDate,
      maxDate,
      hoursLimit,
      showWarning
    };
  },
  computed: {
    ...mapState({
      email: s => s.identity.email
    }),
    dateRepresentation() {
      if (this.mode !== 'datetime' || !this.value) {
        return '';
      }
      return moment(this.value).format('MM/DD/YYYY hh:mm a');
    },
    shouldShowDate() {
      return this.mode === 'datetime' && this.dateRepresentation && this.timezone !== this.myZone;
    },
    needsReschedule() {
      if (!this.calendarEvent) {
        return false;
      }

      if (!this.localValue) {
        return true;
      }

      if (this.mode === 'datetime') {
        return +this.localValue !== +new Date(this.calendarEvent.time);
      }

      const eventDate = new Date(this.calendarEvent.time);

      return (
        +new Date(this.localValue.getFullYear(), this.localValue.getMonth(), this.localValue.getDate()) !==
        +new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate())
      );
    }
  },
  watch: {
    value() {
      if (!this.value) {
        this.localValue = null;
        return;
      }
      this.localValue = new Date(this.value);
      if (this.mode === 'date') {
        this.localValue.setHours(12);
        this.localValue.setMinutes(0);
        this.localValue.setSeconds(0);
        this.localValue.setMilliseconds(0);
      }
    },
    eventSubject(newValue) {
      this.event.subject = newValue;
    }
  },
  async created() {
    if (!this.calendarAvaliable) {
      return;
    }
    try {
      this.event.busy = true;
      this.event.calendars = await httpClient.get(`/api/ms-graph-email-connector/calendars/${this.email}`);
    } catch (error) {
    } finally {
      this.event.busy = false;
    }
  },
  mounted() {
    const delimiters = this.mode === 'datetime' ? ['/', '/', ' ', ':', ' '] : ['/', '/'];
    const blocks = this.mode === 'datetime' ? [2, 2, 4, 2, 2, 2] : [2, 2, 4];

    this.instance = new Cleave(this.$el.querySelector(`input`), {
      delimiters,
      blocks,
      uppercase: true
    });
    this.instance.setRawValue(this.value);
  },
  unmounted() {
    this.instance && this.instance.destroy();
  },

  methods: {
    toggleMenu() {
      this.event.menuVisible = !this.event.menuVisible;
    },
    async addToCalendar(notifySubscribers = true) {
      this.event.menuVisible = false;
      this.event.busy = true;

      try {
        const eventDurationHours = this.event?.duration || 1;
        const categories = this.calendarEventProperties?.event?.categories || null;
        const timezone = this.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
        const url = this.calendarEvent
          ? `/api/ms-graph-email-connector/calendars/${this.email}/event/${this.calendarEvent.id}`
          : `/api/ms-graph-email-connector/calendars/${this.email}/create/event`;
        const calendarEvent = await httpClient.post(url, {
          subject: this.event.subject,
          body: {
            contentType: 'html',
            content: this.event.body
          },
          isAllDay: this.mode !== 'datetime',
          start: {
            dateTime: moment(this.localValue)
              .tz(timezone)
              .format('YYYY-MM-DDTHH:mm:ss'),
            timeZone: timezone
          },
          end: {
            dateTime: moment(this.localValue)
              .add(eventDurationHours, 'hours')
              .tz(timezone)
              .format('YYYY-MM-DDTHH:mm:ss'),
            timeZone: timezone
          },
          categories
        });

        const patch = { ...calendarEvent, time: this.localValue };

        this.$toast.success({
          title: this.calendarEvent ? 'Updated calendar' : 'Added to calendar.',
          message: `${this.event.subject}.`
        });

        if (notifySubscribers) {
          this.$emit('calendarEventCreated', patch);
        }

        this.event.created = true;

        return patch;
      } catch (error) {
        this.$toast.error({
          title: 'Failed to create event in calendar',
          message: `Please, try again later or contact our development team.`
        });

        return null;
      } finally {
        this.resetEventSubmiting();
      }
    },
    async syncIfNeeded() {
      if (!this.needsReschedule) {
        return false;
      }

      return await this.addToCalendar(false);
    },
    resetEventSubmiting() {
      this.event.menuVisible = false;
      this.event.busy = false;
      this.event.subject = this.eventSubject;
      this.event.body = this.eventBody;
    },
    emitChanges(v) {
      if (!v) {
        return;
      }
      let value;
      if (v instanceof Date) {
        value = v;
      } else {
        value = moment(v).toDate();
      }

      if (!value || value.toString() === 'Invalid Date') {
        return;
      }

      value.setSeconds(0);
      if (this.mode !== 'datetime') {
        value = this.setMidday(value);
      } else {
        value = moment(value)
          .tz(this.timezone)
          .format();
      }
      this.$emit('change', value);
      this.$emit('input', value);
      this.$emit('update:modelValue', value);
      this.$emit('update:value', value);
    },
    onInput(date) {
      if (isValid(date)) {
        this.emitChanges(date);
        this.showWarning = false;
      }
    },
    complete(e) {
      e.stopPropagation();
      e.preventDefault();
      let value = this.instance.getRawValue();

      const regexp = this.mode === 'datetime' ? /(\d{12})(AM|PM)/ : /\d{8}/;

      if (regexp.test(value) || value.length === 0) {
        this.emitChanges(value.toUpperCase());
      } else {
        this.localValue = null;
      }
    },
    paste(event) {
      event.preventDefault();

      const match = event.clipboardData.getData('text').match(new RegExp('^\\d\\d?/\\d\\d?/\\d\\d\\d?\\d?'));

      if (!match) {
        return;
      }
      const date = parse(match[0], 'MM/dd/yyyy', new Date());
      if (isValid(date)) {
        this.emitChanges(date);
      }
    },
    setMidday(date) {
      return set(date, { hours: 12, minutes: 0, seconds: 0 });
    },
    onTimezoneChanged(v) {
      this.timezone = v;
      this.emitChanges(this.localValue);
    }
  }
};
</script>

<style lang="scss">
.vc-day-content.is-disabled {
  opacity: 0.4;
}
</style>
<style lang="scss" scoped>
div {
  display: grid;
  grid-template-rows: max-content;

  &.has-label {
    grid-template-rows: max-content max-content;
  }

  > * {
    min-width: 0;
    min-height: 0;
  }

  label {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.025em;
    margin-bottom: 5px;
  }

  .datepicker-wrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    position: relative;
    .dates {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 5px;
      align-items: center;
      .representation {
        position: absolute;
        right: 0;
        top: -20px;
        font-style: italic;
        font-size: 0.75rem;
      }

      &.timezone {
        grid-template-columns: 1fr 1fr;
      }
    }

    .event {
      display: grid;
      grid-template-columns: max-content max-content;
      align-content: center;

      .left-marging {
        margin-left: 5px;
      }

      .create-event {
        align-content: center;
      }
    }
  }

  input {
    padding: 8px 12px;
    font-size: 0.85rem;
    font-weight: 400;
    border-radius: 2px;
    border: 1px solid var(--theme-on-background-accent);
    color: var(--theme-on-background);
    background: var(--theme-background);
    outline: none;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:not([disabled]) {
      // &:hover {
      //   border-color: var(--theme-on-background);
      //   color: var(--theme-on-background);
      // }

      &:focus,
      &:active {
        border-color: var(--theme-on-background);
        color: var(--theme-on-background);
        outline: none;
      }
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  .submit-event {
    display: grid;
    grid-template-columns: 1fr auto auto;
  }

  .event-button {
    padding: 0;
    margin-left: 0.5rem;
  }

  .created {
    color: var(--theme-success);
  }

  .warning {
    color: var(--theme-warning);
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
  }
}
</style>
